import styled from 'styled-components';

export default styled.div`
  margin-bottom: 72px;
  background-color: var(--accent-color);

  .subhead {
    @media (max-width: 1200px) {
      margin-right: 32px;
    }
  }

  .outer-container {
    position: relative;
    max-width: 2000px;
    margin: 0 auto;
    overflow: hidden;
  }
  .hero-content-container {
    padding-top: 140px;
    z-index: 2;
    @media (max-width: 768px) {
      padding-top: 64px;
    }
  }

  .hero-content {
    padding-bottom: 96px;
    @media (max-width: 768px) {
      padding-bottom: 64px;
    }
    h1 {
      margin-bottom: 24px;
    }

    .subhead {
      margin-bottom: 24px;
    }
    .btn {
      margin-bottom: 15px;
    }
  }

  .content-btns {
    .btn-primary {
      margin-right: 24px;
    }
  }

  .top-poly {
    position: absolute;
    top: -400px;
    left: -100px;
    width: 600px;
    display: flex;
    justify-content: center;
  }

  .bottom-poly {
    position: absolute;
    top: 100px;
    right: -20px;
    overflow: hidden;

    .b-poly {
      position: absolute;
    }
  }

  .bot-poly-container {
    position: absolute;
    top: 50px;
    right: -20px;
    max-width: 55%;
    @media (max-width: 991.98px) {
      display: none;
    }
  }

  .bot-poly {
    right: 50px;
    height: 550px;
    width: 1000px;
    overflow: hidden;
    clip-path: path(
      'M304.139 111.378C319.354 92.0994 343.696 82.4117 367.997 85.9638L697.566 134.139C721.867 137.691 742.434 153.944 751.52 176.774L874.748 486.407C883.834 509.237 880.059 535.177 864.844 554.456L658.502 815.913C643.287 835.192 618.945 844.879 594.644 841.327L265.074 793.152C240.774 789.6 220.207 773.348 211.121 750.517L87.8931 440.884C78.807 418.054 82.5822 392.114 97.7968 372.835L304.139 111.378Z'
    );
    background-color: black;
    img {
      position: absolute;
    }
    .overlay {
      background-color: #000;
      opacity: 0.6;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .featured {
      position: absolute;
      top: 350px;
      left: 250px;
      right: 0;
      bottom: 0;
      max-width: 400px;

      h6 {
        color: #fff;
        margin-bottom: 8px;
      }

      .subhead {
        color: #fff;
        margin-bottom: 8px;
      }

      .link {
        color: #fff;

        &:after {
          filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(320deg)
            brightness(103%) contrast(100%);
        }
      }
    }
  }
`;
